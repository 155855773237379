import debounce from "lodash.debounce";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { teamModule, profileModule } from "@store/namespaces";
import { TeamActions, TeamGetters } from "@store/modules/team/types";
import { getFullName, getInitials, hashStringToColor } from "@helpers";
import { ProfileGetters } from "@store/modules/profile/types";
import { Role } from "@/types/role";
import { TeamMember } from "@/types/team";
import { MultiSelectInstance } from "@/types/components/multiselect";

import MultiSelect from "@/components/MultiSelect/MultiSelect.vue";
import Avatar from "@/components/Avatar/Avatar.vue";

enum MultiselectVariant {
  TEAMLEADS = "teamleads",
  MEDIABUYERS = "mediabuyers",
  TEAMLEADS_AND_MEDIABUYERS = "teamleads-and-mediabuyers",
}

@Component({
  components: {
    Avatar,
    MultiSelect,
  },
})
export default class TeamMembersMultiselect extends Vue {
  @Ref("multiselectTeamlead")
  private readonly multiselectTeamleadRef?: MultiSelectInstance;

  @teamModule.Action("fetchTeamMembers")
  private readonly fetchTeamMembersAction!: TeamActions["fetchTeamMembers"];
  @teamModule.Getter("membersLoading")
  private readonly membersLoadingGetter!: TeamGetters["membersLoading"];
  @teamModule.Getter("members")
  private readonly membersGetter!: TeamGetters["members"];
  @teamModule.Getter("teamleadMembers")
  private readonly teamleadMembersGetter!: TeamGetters["teamleadMembers"];
  @teamModule.Getter("mediabuyerMembers")
  private readonly mediabuyerMembersGetter!: TeamGetters["mediabuyerMembers"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @profileModule.Getter("profileAvatar")
  private readonly profileAvatarGetter!: ProfileGetters["profileAvatar"];
  @profileModule.Getter("profileFullName")
  private readonly profileFullNameGetter!: ProfileGetters["profileFullName"];
  @profileModule.Getter("profileId")
  private readonly profileIdGetter!: ProfileGetters["profileId"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];

  @Prop({ type: String, default: MultiselectVariant.TEAMLEADS_AND_MEDIABUYERS })
  private readonly variant!: MultiselectVariant;

  @Prop({ type: Array, default: () => [] })
  private readonly teamleadEmails!: string[];

  @Prop({ type: Array, default: () => [] })
  private readonly mediabuyerEmails!: string[];

  private setActiveMember!: ReturnType<typeof debounce>;
  private activeMemberCid: string | null = null;
  private appendContentOffsetTop = 0;

  private get activeMember() {
    const activeMemberCid = this.activeMemberCid;

    return this.items.find(({ cid }) => cid === activeMemberCid);
  }

  private get loading() {
    return this.membersLoadingGetter;
  }

  private get items() {
    let teamMembers = this.membersGetter;

    switch (this.variant) {
      case MultiselectVariant.TEAMLEADS_AND_MEDIABUYERS:
      case MultiselectVariant.TEAMLEADS:
        teamMembers = this.teamleadMembersGetter;
        break;

      case MultiselectVariant.MEDIABUYERS:
        teamMembers = this.mediabuyerMembersGetter;
        break;

      default:
        teamMembers = this.membersGetter;
        break;
    }

    const items = this.mapItems(teamMembers);

    if (
      [
        MultiselectVariant.TEAMLEADS_AND_MEDIABUYERS,
        MultiselectVariant.TEAMLEADS,
      ].includes(this.variant) &&
      this.userHasRoleGetter([Role.ROLE_TEAMLEAD, Role.ROLE_OWNER])
    ) {
      items.unshift({
        cid: this.profileIdGetter.toString(),
        avatar: this.profileAvatarGetter,
        email: this.profileEmailGetter,
        fullName: this.profileFullNameGetter,
        initials: getInitials(this.profileFullNameGetter),
        enabled: true,
        parentMember: undefined,
        members: [],
      });
    }

    return items;
  }

  private get totalItemsLength() {
    if (!this.isShowNestedMembers) {
      return;
    }

    return this.items.reduce((count, { members }) => {
      const membersCount = members?.length ?? 0;

      return count + membersCount + 1;
    }, 0);
  }

  private get selectedItemsLength() {
    if (!this.isShowNestedMembers) {
      return;
    }

    return this.mediabuyerEmails.length + this.teamleadEmails.length;
  }

  private get isShowNestedMembers() {
    return this.variant === MultiselectVariant.TEAMLEADS_AND_MEDIABUYERS;
  }

  private onInputMediabuyerEmails(value: string[]) {
    this.$emit(
      "update:mediabuyer-emails",
      this.mediabuyerEmails
        .filter(
          (email) =>
            !this.activeMember?.members?.some(
              (member) => member.email === email
            )
        )
        .concat(value)
    );
  }

  private onInputTeamleadEmails(value: string[]) {
    this.$emit("update:teamlead-emails", value);
  }

  private getSelectedMediabuyers(teamlead: TeamMember) {
    return (
      teamlead.members?.filter(({ email }) =>
        this.mediabuyerEmails.includes(email)
      ).length || 0
    );
  }

  private mapItems(teamMembers: TeamMember[]) {
    return teamMembers.map(
      ({
        email,
        firstName,
        lastName,
        enabled,
        avatar,
        cid,
        members,
        parentMember,
      }) => {
        const fullName = getFullName({
          firstName,
          lastName,
          fallback: email,
        });

        return {
          cid,
          avatar,
          email,
          enabled,
          fullName,
          members,
          parentMember,
          initials: getInitials(fullName),
        };
      }
    );
  }

  private onMouseEnterMemberItem(item: TeamMember, event: MouseEvent) {
    if (!this.isShowNestedMembers) {
      return;
    }

    this.setActiveMember(item.cid);

    const memberItemEl = (event.currentTarget as HTMLElement).parentElement
      ?.parentElement;

    const memberItemsEl = memberItemEl?.parentElement?.parentElement;

    this.appendContentOffsetTop =
      memberItemEl && memberItemsEl
        ? memberItemEl.offsetTop - memberItemsEl.scrollTop
        : 0;
  }

  private onMouseLeaveMemberItem(item: TeamMember) {
    if (item.cid !== this.activeMemberCid) {
      return;
    }

    this.setActiveMember.cancel();
  }

  private onUpdateShowedList(showed: boolean) {
    this.setActiveMember(showed ? this.activeMemberCid : null);
  }

  private getTeamMemberColor(email: string) {
    return hashStringToColor(email);
  }

  private async fetchTeamMembers() {
    await this.fetchTeamMembersAction();
  }

  private selectAll() {
    this.multiselectTeamleadRef?.selectAll();

    if (this.isShowNestedMembers) {
      this.$emit(
        "update:mediabuyer-emails",
        this.mediabuyerMembersGetter.map(({ email }) => email)
      );
    }
  }

  private created() {
    this.setActiveMember = debounce((cid: string | null) => {
      this.activeMemberCid = cid || null;
    }, 100);
  }

  private mounted() {
    this.fetchTeamMembers();
  }
}
