import { Component, Mixins } from "vue-property-decorator";

import FilterTopBarMixin from "../../common/mixins/filterTopBar.mixin";

import Menu from "@/components/Menu/Menu.vue";

@Component({
  components: {
    Menu,
  },
})
export default class DesktopFilterTableTopBar extends Mixins(
  FilterTopBarMixin
) {
  private isShowFiltersMenu = false;
  private isShowPreferenceMenu = false;

  private get downloadButtons() {
    return [
      {
        icon: "download-csv.png",
        tooltip: this.$vuetify.lang.t("$vuetify.info.download_to_csv"),
        loading: this.downloadingCSV,
        on: {
          click: this.downloadCSV,
        },
      },
      {
        icon: "download-excel.png",
        tooltip: this.$vuetify.lang.t("$vuetify.info.download_to_excel"),
        loading: this.downloadingXLSX,
        on: {
          click: this.downloadXLSX,
        },
      },
    ];
  }

  private onInputFiltersMenu(showed: boolean) {
    this.isShowFiltersMenu = showed;
  }

  private onInputPreferenceMenu(showed: boolean) {
    this.isShowPreferenceMenu = showed;
  }

  private created() {
    this.createdHook();
  }

  private mounted() {
    this.mountedHook();
  }
}
