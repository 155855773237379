import { Component, Mixins } from "vue-property-decorator";

import DateRangePickerMixin from "../../common/mixins/DateRangePicker.mixin";

import Menu from "@/components/Menu/Menu.vue";

@Component({
  components: {
    Menu,
  },
})
export default class DesktopDateRangePicker extends Mixins(
  DateRangePickerMixin
) {
  private get menuWidth() {
    if (this.langCode === "en") {
      return 705;
    }

    return 745;
  }

  private get periodBtnWidth() {
    if (this.langCode === "en") {
      return 130;
    }

    return 170;
  }

  private mounted() {
    this.mountedHook();
  }
}
