import { Mixins, Component } from "vue-property-decorator";
import { Prop } from "vue-property-decorator";
import LangMixin from "@/mixins/lang.mixin";

import Menu from "@/components/Menu/Menu.vue";

@Component({
  components: {
    Menu,
  },
})
export default class LangSwitcher extends Mixins(LangMixin) {
  @Prop({ type: Boolean, default: false })
  private readonly disabled!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly miniVariant!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly dense!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly top!: boolean;
  @Prop({ type: Boolean, default: true })
  private readonly left!: boolean;
  @Prop({ type: Number, default: -10 })
  private readonly nudgeTop!: number;

  private get minHeightCurrent() {
    // if (this.miniVariant || this.dense) return;

    return this.$vuetify.breakpoint.xlOnly ? 48 : 36;
  }
}
