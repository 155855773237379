import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class LottieBanner extends Vue {
  @Prop({ type: [String, Number], required: true })
  private readonly aspectRatio!: number | string;
  @Prop({ type: String, required: true }) private readonly src!: string;
  @Prop({ type: Number, default: 1 })
  private readonly speed!: number;
  @Prop({ type: [String, Number] })
  private readonly maxWidth?: number | string;

  private isActive = true;

  private async mounted() {
    await import("@lottiefiles/lottie-player");

    if (!this.isActive) {
      return;
    }

    const lottiePlayerEl = document.createElement("lottie-player");

    lottiePlayerEl.setAttribute("autoplay", "");
    lottiePlayerEl.setAttribute("loop", "");
    lottiePlayerEl.setAttribute("mode", "normal");
    lottiePlayerEl.setAttribute("speed", this.speed.toString());

    lottiePlayerEl.addEventListener("play", () => {
      lottiePlayerEl.style.opacity = "1";
    });

    this.$watch(
      () => {
        return this.src;
      },
      (src) => {
        lottiePlayerEl.style.opacity = "0";
        lottiePlayerEl.setAttribute("src", src);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lottiePlayerEl.load(src);
      },
      {
        immediate: true,
      }
    );

    this.$el.appendChild(lottiePlayerEl);

    this.$once("hook:beforeDestroy", () => {
      lottiePlayerEl.remove();
    });
  }

  private beforeDestroy() {
    this.isActive = false;
  }
}
