import { Component, Mixins, Prop } from "vue-property-decorator";

import { Period } from "@/types/datepicker";
import DateRangePickerMixin from "../../common/mixins/DateRangePicker.mixin";

import Dialog from "@/components/Dialog/Dialog.vue";

@Component({
  components: {
    Dialog,
  },
})
export default class MobileDateRangePicker extends Mixins(
  DateRangePickerMixin
) {
  @Prop({ type: String, default: "$calendar" })
  private readonly inputIcon?: string;

  private get disabledSave() {
    return this.pickerVal.length < 2;
  }

  private get formatedValue() {
    const selectedPeriod = this.selectedPeriod;

    if (selectedPeriod === Period.CUSTOM) {
      return this.formatedRangeDate;
    }

    return this.periodButtons.find(({ value }) => value === selectedPeriod)
      ?.text;
  }

  private onSaveDate() {
    this.toggleDropdown(false);
    this.inputValue();
  }

  private async scrollToActivePeriod() {
    await this.$nextTick();

    document.body
      .querySelector(".date-range-picker__dialog")
      ?.querySelector(".date-range-picker__period_active")
      ?.scrollIntoView({ inline: "center", behavior: "smooth" });
  }

  private mounted() {
    this.mountedHook();

    let showedContentTimeoutId = 0;

    this.$watch(
      () => {
        return this.showedDropdown;
      },
      (showedDropdown) => {
        window.clearTimeout(showedContentTimeoutId);

        if (!showedDropdown) return;

        showedContentTimeoutId = window.setTimeout(() => {
          this.scrollToActivePeriod();
        }, 350);
      }
    );

    this.$watch(() => {
      return this.selectedPeriod;
    }, this.scrollToActivePeriod);
  }
}
