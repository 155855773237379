import { Component, Vue, Prop, Ref, Model } from "vue-property-decorator";
import debounce from "lodash.debounce";
import { downloadCSV } from "@/lib/csv";
import { downloadXLSX } from "@/lib/xlsx";
import {
  DownloadedData,
  TopBarFilter,
  TableHeaderItems,
  TableHeaderValue,
} from "@/types/components/filter-table-top-bar";

@Component
export default class FilterTopBarMixin extends Vue {
  @Model("input", { type: String, default: undefined })
  private readonly value?: string;
  @Ref("searchInput") private readonly searchInputRef?: HTMLInputElement;
  @Prop({ type: Boolean, default: false }) private readonly debounced!: boolean;
  @Prop({ type: Function })
  protected readonly downloadedDataFunc?: () => Promise<DownloadedData>;
  @Prop({ type: Array, default: () => [] })
  protected readonly tableHeaderItems!: TableHeaderItems;
  @Prop({ type: Array, default: () => [] })
  private readonly tableHeaderValue!: TableHeaderValue;
  @Prop({ type: Boolean, default: false }) protected readonly loading!: boolean;
  @Prop({ type: Object }) private readonly filter?: TopBarFilter;
  @Prop({ type: Array, default: () => [] })
  protected filterFields!: {
    title: string;
    type: "checkbox";
    name: string;
    items: {
      text: string;
      value: unknown;
    }[];
  }[];
  @Prop({ type: Object })
  private readonly successButton?: {
    title: string;
    icon?: string;
    id?: string;
    listeners: Record<string, unknown>;
  };
  @Prop({ type: String })
  private readonly filtersContainerId?: string;
  @Prop({ type: String })
  private readonly downloadFilesContainerId?: string;
  @Prop({ type: String })
  private readonly filtersButtonId?: string;

  private localFilter: TopBarFilter = {};
  private localTableHeaderValue: TableHeaderValue = [];
  protected searchStr = "";
  protected downloadingCSV = false;
  protected downloadingXLSX = false;

  protected get isShowClearButton(): boolean {
    return !!this.searchStr?.trim().length;
  }

  protected get hiddenSearchInput(): boolean {
    return this.value === undefined;
  }

  protected get showedRefreshButton(): boolean {
    return !!this.$listeners.refresh;
  }

  protected get filterButtonColor() {
    const filter = this.filter;

    if (!filter) {
      return "red";
    }

    let totalItemsLength = 0;
    let totalSelectedItemsLength = 0;

    const filterFields = this.filterFields;

    for (const filterField of filterFields) {
      const filterFieldValue = filter[filterField.name];

      for (const { selected } of filterFieldValue) {
        totalItemsLength++;

        if (selected) {
          totalSelectedItemsLength++;
        }

        if (
          totalSelectedItemsLength > 0 &&
          totalSelectedItemsLength < totalItemsLength
        ) {
          return "amber accent-3";
        }
      }
    }

    return !totalSelectedItemsLength ? "red" : "green darken-1";
  }

  protected async downloadCSV() {
    if (!this.downloadedDataFunc) return;

    this.downloadingCSV = true;

    try {
      const data = await this.downloadedDataFunc();

      downloadCSV(data);
    } finally {
      this.downloadingCSV = false;
    }
  }

  protected async downloadXLSX() {
    if (!this.downloadedDataFunc) return;

    this.downloadingXLSX = true;

    try {
      const data = await this.downloadedDataFunc();

      downloadXLSX(data);
    } finally {
      this.downloadingXLSX = false;
    }
  }

  protected onRefresh(): void {
    this.$emit("refresh");
  }

  private onSelectFilterFieldItem({
    filterFieldName,
    filterFieldItemValue,
    filterFieldItemSelected,
  }: {
    filterFieldName: string;
    filterFieldItemValue: string;
    filterFieldItemSelected: boolean;
  }) {
    const newFilter = {
      ...this.localFilter,
      [filterFieldName]: this.localFilter[filterFieldName].map(
        ({ value, selected }) =>
          Object.freeze({
            value,
            selected:
              value === filterFieldItemValue
                ? filterFieldItemSelected
                : selected,
          })
      ),
    };

    this.localFilter = newFilter;
  }

  private clearFilter() {
    const resetedFilter = this.filterFields.reduce<TopBarFilter>(
      (acc, { name, items }) => {
        acc[name] = items.map((item) =>
          Object.freeze({
            value: item.value,
            selected: false,
          })
        );

        return acc;
      },
      {}
    );

    const newFilter = { ...this.localFilter, ...resetedFilter };

    this.localFilter = newFilter;
  }

  private selectAllFilter() {
    const selectedAll = this.filterFields.reduce<TopBarFilter>(
      (acc, { name, items }) => {
        acc[name] = items.map((item) =>
          Object.freeze({
            value: item.value,
            selected: true,
          })
        );

        return acc;
      },
      {}
    );

    const newFilter = { ...this.localFilter, ...selectedAll };

    this.localFilter = newFilter;
  }

  private toggleAllTableHeaders(selected: boolean) {
    const selectedTableHeaders = this.localTableHeaderValue.map((item) =>
      Object.freeze({
        ...item,
        selected,
      })
    );

    this.localTableHeaderValue = selectedTableHeaders;
  }

  private isSelectedFilterFieldItem({
    filterFieldName,
    filterFieldItemValue,
  }: {
    filterFieldName: string;
    filterFieldItemValue: unknown;
  }) {
    return this.localFilter[filterFieldName].some(
      ({ selected, value }) => selected && value === filterFieldItemValue
    );
  }

  private isShowTableHeader(columnName: string) {
    return this.localTableHeaderValue.some(
      (item) => item.columnName === columnName && item.selected
    );
  }

  private onChangeShowedTableHeaders(columnName: string, selected: boolean) {
    const selectedTableHeaders = this.localTableHeaderValue.map((item) =>
      Object.freeze({
        ...item,
        selected: item.columnName === columnName ? selected : item.selected,
      })
    );

    this.localTableHeaderValue = selectedTableHeaders;
  }

  private onInputSearchStr() {
    this.$emit("update:loading-search", true);
    this.debouncedInputSearchStr();
  }

  private clearInputSearchStr() {
    this.searchStr = "";
    this.searchInputRef?.focus();
    this.onInputSearchStr();
  }

  private debouncedInputSearchStr() {
    this.$emit("input", this.searchStr);
    this.$emit("update:loading-search", false);
  }

  private debouncedChangeFilter(value: TopBarFilter) {
    this.$emit("change:filter", value);
    this.$emit("update:loading-filter", false);
  }

  private debouncedChangeTableHeaderValue(value: TableHeaderValue) {
    this.$emit("change:table-header-value", value);
  }

  protected createdHook(): void {
    this.$watch(
      () => {
        return this.value?.trim() || "";
      },
      (value) => {
        this.searchStr = value;
      },
      {
        immediate: true,
      }
    );
    this.$watch(
      () => {
        return this.filter || {};
      },
      (filter) => {
        this.localFilter = filter;
      },
      { immediate: true }
    );

    this.$watch(
      () => {
        return this.tableHeaderValue;
      },
      (tableHeaderValue) => {
        this.localTableHeaderValue = tableHeaderValue;
      },
      { immediate: true }
    );

    this.debouncedChangeTableHeaderValue = debounce(
      this.debouncedChangeTableHeaderValue,
      250
    );

    if (this.debounced) {
      this.debouncedInputSearchStr = debounce(
        this.debouncedInputSearchStr,
        350
      );
      this.debouncedChangeFilter = debounce(this.debouncedChangeFilter, 250);
    }
  }

  protected mountedHook(): void {
    this.$watch(
      () => {
        return this.localFilter;
      },
      (newFilter) => {
        if (newFilter === this.filter) return;

        this.$emit("update:loading-filter", true);
        this.debouncedChangeFilter(newFilter);
      }
    );

    this.$watch(
      () => {
        return this.localTableHeaderValue;
      },
      (newTableHeaderValue) => {
        if (newTableHeaderValue === this.tableHeaderValue) return;

        this.debouncedChangeTableHeaderValue(newTableHeaderValue);
      }
    );
  }
}
