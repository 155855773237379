import { Getter, State } from "vuex-class";
import { Vue, Component } from "vue-property-decorator";
import { ErrorStatus } from "@/types/errorStatus";
import { RootGetters, RootState } from "@store/types";

import OfflineAlert from "@/components/OfflineAlert/OfflineAlert.vue";
import DashboardLayout from "@/layouts/DashboardLayout/DashboardLayout.vue";
import RedesignLayout from "@/redesign/layouts/RedesignLayout/RedesignLayout.vue";
import AuthScreen from "@/redesign/layouts/AuthScreen/AuthScreen.vue";

@Component({
  components: {
    OfflineAlert,
    DashboardLayout,
    RedesignLayout,
    AuthScreen,
    AuthLayout: () => import("@/layouts/AuthLayout/AuthLayout.vue"),
    EmptyLayout: () => import("@/layouts/EmptyLayout/EmptyLayout.vue"),
    ServiceUnavailable: () =>
      import("@/views/Error/ServiceUnavailable/ServiceUnavailable.vue"),
    InternalServerError: () => import("@/views/Error/500/500.vue"),
  },
})
export default class LayoutSwitch extends Vue {
  @State("isReady") private readonly isReadyApp!: RootState["isReady"];
  @Getter("errorStatus")
  private readonly errorStatusGetter!: RootGetters["errorStatus"];

  private get layoutClassName() {
    return (
      this.layoutComponent
        ?.toString()
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .toLowerCase() || ""
    );
  }

  private get layoutComponent() {
    return this.$route.meta?.layout || "DashboardLayout";
  }

  private get screenComponent() {
    return this.$route.meta?.screen;
  }

  private get routerViewKey() {
    const { path: routePath } = this.$route;

    if (this.layoutComponent === "RedesignLayout") {
      return routePath;
    }

    const deviceType = this.$vuetify.breakpoint.mobile ? "mobile" : "desktop";

    return `${routePath}_${deviceType}`;
  }

  private get isServiceUnavailableError() {
    return this.errorStatusGetter === ErrorStatus.SERVICE_UNAVAILABLE;
  }

  private get isNotFoundError() {
    return this.errorStatusGetter === ErrorStatus.NOT_FOUND;
  }

  private get isInternalServerError() {
    return this.errorStatusGetter === ErrorStatus.INTERNAL_SERVER_ERROR;
  }
}
