import { Component, Vue, Prop, Ref, Model } from "vue-property-decorator";
import { cardGroupModule } from "@store/namespaces";
import {
  CardGroupGetters,
  CardGroupActions,
} from "@store/modules/card/modules/group/types";
import { hashStringToColor } from "@helpers";
import { MultiSelectInstance } from "@/types/components/multiselect";

import MultiSelect from "@/components/MultiSelect/MultiSelect.vue";

@Component({
  components: {
    MultiSelect,
  },
})
export default class CardGroupMultiselect extends Vue {
  @Ref("multiselect") private readonly multiselectRef?: MultiSelectInstance;

  @Model("input", { type: Array, required: true })
  private readonly cardGroupIds!: number[];

  @Prop({ type: Array }) private readonly items?: {
    text: string;
    value: number;
  }[];
  @Prop({ type: String, default: "id" }) private readonly itemValue!: string;
  @Prop({ type: String, default: "name" }) private readonly itemText!: string;

  @cardGroupModule.Getter("cardGroups")
  private readonly cardGroupsGetter!: CardGroupGetters["cardGroups"];
  @cardGroupModule.Getter("loadingCardGroups")
  private readonly loadingCardGroupsGetter!: CardGroupGetters["loadingCardGroups"];
  @cardGroupModule.Action("fetchCardGroups")
  private readonly fetchCardGroupsAction!: CardGroupActions["fetchCardGroups"];

  private get localItems() {
    return this.items || this.cardGroupsGetter;
  }

  private get loading() {
    if (this.items) {
      return false;
    }

    return this.loadingCardGroupsGetter;
  }

  private hashStringToColor(val: string) {
    return hashStringToColor(val);
  }

  private selectAll() {
    this.multiselectRef?.selectAll();
  }

  private mounted() {
    if (!this.items) {
      this.fetchCardGroupsAction();
    }

    this.$watch(
      () => {
        return this.localItems.length > 0;
      },
      (isReady) => {
        if (!isReady) return;

        this.$emit("ready", this.localItems);
      },
      { immediate: true }
    );
  }
}
