import { Vue, Component, Prop, Model } from "vue-property-decorator";

import Dialog from "@/components/Dialog/Dialog.vue";

@Component({
  components: {
    Dialog,
    ConfirmCard: () => import("@/components/ConfirmCard/ConfirmCard.vue"),
  },
})
export default class ConfirmDialog extends Vue {
  @Model("input", { required: true }) private readonly showed!: boolean;
  @Prop({ type: Number, default: 400 }) private readonly maxWidth!: number;
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly persistent!: boolean;

  private toggleDialog(value: boolean) {
    this.$emit("input", value);

    this.$emit(value ? "showed" : "hidden");
  }

  private onConfirm() {
    this.$emit("confirm");
  }

  private onCancel() {
    this.toggleDialog(false);
    this.$emit("cancel");
  }
}
