import { render, staticRenderFns } from "./LottieBanner.vue?vue&type=template&id=1bdbe278&scoped=true"
import script from "./LottieBanner.ts?vue&type=script&lang=ts&external"
export * from "./LottieBanner.ts?vue&type=script&lang=ts&external"
import style0 from "./LottieBanner.scss?vue&type=style&index=0&id=1bdbe278&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bdbe278",
  null
  
)

export default component.exports