import { render, staticRenderFns } from "./MultiSelect.vue?vue&type=template&id=b06866d4&scoped=true"
import script from "./MultiSelect.ts?vue&type=script&lang=ts&external"
export * from "./MultiSelect.ts?vue&type=script&lang=ts&external"
import style0 from "./MultiSelect.scss?vue&type=style&index=0&id=b06866d4&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b06866d4",
  null
  
)

export default component.exports