import { Vue, Component, Model, Prop } from "vue-property-decorator";
import {
  VFadeTransition,
  VExpandTransition,
  VScaleTransition,
  VScrollXTransition,
  VScrollYTransition,
  VTabTransition,
  VFabTransition,
  VMenuTransition,
  VSlideYTransition,
  VExpandXTransition,
  VDialogTransition,
  VSlideXTransition,
  VSlideYReverseTransition,
  VScrollYReverseTransition,
} from "vuetify/lib";

import { uniqCid, yieldToMain } from "@helpers";

const visibleObserver: {
  instance: IntersectionObserver | null;
  handlers: Record<string, () => void>;
} = {
  instance: null,
  handlers: {},
};

@Component({
  components: {
    VFadeTransition,
    VExpandTransition,
    VScaleTransition,
    VScrollXTransition,
    VScrollYTransition,
    VTabTransition,
    VFabTransition,
    VMenuTransition,
    VSlideYTransition,
    VExpandXTransition,
    VDialogTransition,
    VSlideXTransition,
    VSlideYReverseTransition,
    VScrollYReverseTransition,
  },
})
export default class LazyComponent extends Vue {
  @Model("input", { type: Boolean, default: false })
  readonly visibled!: boolean;
  @Prop({ type: String }) private readonly id?: string;
  @Prop({ type: [Number, String] }) private readonly minHeight?:
    | number
    | string;
  @Prop({ type: [Number, String] }) private readonly maxWidth?: number | string;
  @Prop({ type: [Number, String] }) private readonly minWidth?: number | string;
  @Prop({ type: String, default: "fade-transition" })
  private readonly transition!: string;

  private localVisibled = false;
  private offset = 100;
  private divId = "";

  private created() {
    this.divId = this.divId || `lazy-component-${uniqCid()}`;

    this.$watch(
      () => {
        return this.localVisibled;
      },
      (visibled) => {
        this.$emit("input", visibled);
      }
    );

    this.$watch(
      () => {
        return this.visibled || this.localVisibled;
      },
      (visibled) => {
        this.localVisibled = visibled;
      },
      {
        immediate: true,
      }
    );
  }

  private async initVisibleObserver() {
    if (this.localVisibled) return;

    if (!visibleObserver.instance) {
      visibleObserver.instance = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) return;

            const el = entry.target;

            const targetDivId = el.id;

            const visibleObserverHandler =
              visibleObserver.handlers[targetDivId];

            if (!visibleObserverHandler) return;

            visibleObserverHandler();
            delete visibleObserver.handlers[targetDivId];
            visibleObserver.instance?.unobserve(el);
          });
        },
        {
          rootMargin: `${this.offset}px 0px ${this.offset}px 0px`,
        }
      );
    }

    visibleObserver.handlers[this.divId] = async () => {
      await yieldToMain();

      this.localVisibled = true;
    };
    visibleObserver.instance.observe(this.$el);

    this.$once("hook:beforeDestroy", () => {
      delete visibleObserver.handlers[this.divId];
      visibleObserver.instance?.unobserve(this.$el);
    });
  }

  private mounted() {
    this.initVisibleObserver();
  }
}
