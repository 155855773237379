import { Component, Vue, Ref, Prop } from "vue-property-decorator";
import {
  formatDatePickerVal,
  parseDatePickerVal,
  getFormatedPeriodDate,
} from "@/lib/date";
import { profileModule } from "@store/namespaces";
import { ProfileGetters } from "@store/modules/profile/types";
import { Period } from "@/types/datepicker";

@Component
export default class DateRangePickerMixin extends Vue {
  @profileModule.Getter("profileLanguage")
  private readonly profileLanguageGetter!: ProfileGetters["profileLanguage"];

  @Ref("datePickerStart") private readonly datePickerStartRef?: {
    tableDate: string;
  };
  @Ref("datePickerEnd") private readonly datePickerEndRef?: {
    tableDate: string;
  };
  @Prop({ type: Array, required: true }) private readonly value!: string[];

  protected pickerVal: string[] = [];
  private isFocusedRangeInput = false;

  private rangeStartInputValue = "";
  private rangeEndInputValue = "";
  protected showedDropdown = false;

  protected get langCode(): string {
    return this.profileLanguageGetter(this.$vuetify.lang.current);
  }

  private get firstDayOfWeek() {
    return ["ru", "uk"].includes(this.langCode) ? 1 : 0;
  }

  private get formatedRangeDateStart() {
    const start = this.value[0];

    if (start) {
      return parseDatePickerVal(start)
        .toLocaleString(this.langCode, {
          month: "long",
          day: "2-digit",
          year: "numeric",
        })
        .split(" ")
        .slice(0, 3)
        .join(" ");
    }
  }

  private get formatedRangeDateEnd() {
    const end = this.value[1];

    if (end) {
      return parseDatePickerVal(end)
        .toLocaleString(this.langCode, {
          month: "long",
          day: "2-digit",
          year: "numeric",
        })
        .split(" ")
        .slice(0, 3)
        .join(" ");
    }
  }

  protected get formatedRangeDate(): string {
    if (!this.formatedRangeDateStart || !this.formatedRangeDateEnd) {
      return this.$vuetify.lang.t("$vuetify.dashboard.table.header.date");
    }

    return `${this.formatedRangeDateStart} - ${this.formatedRangeDateEnd}`;
  }

  private get datePickerStartMax() {
    // ToDo: Доработать валидацию
    return undefined;
    if (!this.pickerVal[0]) return undefined;

    const date = new Date(this.pickerVal[0]);

    return this.formatDatePickerVal(
      new Date(new Date(date).setMonth(date.getMonth() + 1)).setDate(0)
    );
  }

  private get datePickerEndMin() {
    // ToDo: Доработать валидацию
    return undefined;
    if (this.pickerVal[1]) {
      const date = new Date(this.pickerVal[1]).setDate(1);

      return this.formatDatePickerVal(date);
    }

    if (this.pickerVal[0]) {
      const date = new Date(this.pickerVal[0]);

      return this.formatDatePickerVal(
        new Date(new Date(date).setMonth(date.getMonth() + 1)).setDate(1)
      );
    }
  }

  protected get periodButtons(): {
    text: string;
    value: Period;
  }[] {
    return [
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.datepicker.today"),
        value: Period.TODAY,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.datepicker.yesterday"),
        value: Period.YESTERDAY,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.datepicker.last_3_days"),
        value: Period.LAST_3_DAYS,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.datepicker.last_7_days"),
        value: Period.LAST_7_DAYS,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.datepicker.last_14_days"
        ),
        value: Period.LAST_14_DAYS,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.datepicker.current_month"
        ),
        value: Period.CURRENT_MONTH,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.datepicker.last_month"),
        value: Period.LAST_MONTH,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.datepicker.prev_month"),
        value: Period.PREV_MONTH,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.datepicker.last_3_months"
        ),
        value: Period.LAST_3_MONTHS,
      },
      {
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.datepicker.last_6_months"
        ),
        value: Period.LAST_6_MONTHS,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.datepicker.custom"),
        value: Period.CUSTOM,
      },
    ];
  }

  protected get selectedPeriod(): Period {
    const [start, end] = this.pickerVal;

    return (
      Object.values(Period).find((period) => {
        const { start: startPeriod, end: endPeriod } =
          getFormatedPeriodDate(period);

        return startPeriod === start && endPeriod === end;
      }) || Period.CUSTOM
    );
  }

  protected set selectedPeriod(period: Period) {
    const { start, end } = getFormatedPeriodDate(period);

    this.pickerVal = [start, end];
  }

  protected toggleDropdown(showed = !this.showedDropdown): void {
    this.showedDropdown = showed;
  }

  private onInputRangeValue(value: string, type: "start" | "end") {
    let [d = "", m = "", y = ""] = value.includes(".")
      ? value.split(".")
      : value.split("/");

    d = d.trim();
    m = m.trim();
    y = y.trim();

    const year = parseInt(y);
    const month = parseInt(m) - 1;
    const day = parseInt(d);

    if (!year || !month || !day) {
      return;
    }

    const date = new Date(year, month, day);

    if (
      !date.getTime() ||
      date.getDate() !== day ||
      date.getMonth() !== month ||
      date.getFullYear() !== year
    ) {
      return;
    }

    if (type === "start") {
      this.pickerVal = [
        formatDatePickerVal(date.getTime()),
        ...this.pickerVal.slice(1),
      ];
    } else if (type === "end") {
      this.pickerVal = [
        ...this.pickerVal.slice(0, 1),
        formatDatePickerVal(date.getTime()),
      ];
    }
  }

  private onInputPicker(val: string[]) {
    const dateStart = val[0] ? parseDatePickerVal(val[0]) : null;
    const dateEnd = val[1] ? parseDatePickerVal(val[1]) : null;

    if (dateEnd && dateStart) {
      this.pickerVal =
        dateEnd.getTime() > dateStart.getTime()
          ? [
              this.formatDatePickerVal(dateStart.getTime()),
              this.formatDatePickerVal(dateEnd.getTime()),
            ]
          : [
              this.formatDatePickerVal(dateEnd.getTime()),
              this.formatDatePickerVal(dateStart.getTime()),
            ];
    } else if (dateStart) {
      this.pickerVal = [this.formatDatePickerVal(dateStart.getTime())];
    } else {
      this.pickerVal = [];
    }
  }

  private formatDatePickerVal(date: number) {
    return formatDatePickerVal(date);
  }

  protected inputValue(): void {
    this.$emit("input", this.pickerVal);
  }

  protected mountedHook(): void {
    this.$watch(
      () => {
        return [this.value, this.showedDropdown].join("-");
      },
      () => {
        this.pickerVal = this.value;
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      () => {
        return this.pickerVal;
      },
      () => {
        if (this.$vuetify.breakpoint.mobile) {
          return;
        }

        this.inputValue();
      }
    );

    this.$watch(
      () => {
        return this.pickerVal.concat(this.showedDropdown.toString()).join("-");
      },
      () => {
        if (!this.showedDropdown) return;

        this.$nextTick(() => {
          if (!this.pickerVal.length) return;

          const start = this.pickerVal[0];
          let end = this.pickerVal[1];

          if (this.datePickerStartRef && start) {
            this.datePickerStartRef.tableDate = start;
          }

          if (this.datePickerEndRef && end) {
            if (end === start) {
              end = formatDatePickerVal(
                new Date(end).setMonth(new Date(end).getMonth() + 1)
              );
            }

            this.datePickerEndRef.tableDate = end;
          }
        });
      }
    );

    this.$watch(
      () => {
        return [
          this.isFocusedRangeInput,
          this.langCode,
          ...this.pickerVal,
        ].join("-");
      },
      () => {
        if (this.isFocusedRangeInput) return;

        this.rangeStartInputValue = "";
        this.rangeEndInputValue = "";

        this.$nextTick(() => {
          const [start, end] = this.pickerVal;

          if (start) {
            this.rangeStartInputValue = parseDatePickerVal(
              start
            ).toLocaleString(this.langCode, {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            });
          }

          if (end) {
            this.rangeEndInputValue = parseDatePickerVal(end).toLocaleString(
              this.langCode,
              {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              }
            );
          }
        });
      },
      { immediate: true }
    );
  }
}
