import { render, staticRenderFns } from "./LangSwitcher.vue?vue&type=template&id=18ca281f&scoped=true"
import script from "./LangSwitcher.ts?vue&type=script&lang=ts&external"
export * from "./LangSwitcher.ts?vue&type=script&lang=ts&external"
import style0 from "./LangSwitcher.scss?vue&type=style&index=0&id=18ca281f&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18ca281f",
  null
  
)

export default component.exports