var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'base-img overflow-hidden',
    {
      'base-img_defined_ratio': _vm.aspectRatio,
      'base-img_contain': _vm.contain,
      'base-img_cover': !_vm.contain,
    },
  ],style:({
    '--aspect-ratio': _vm.aspectRatio || undefined,
    width: +_vm.width ? `${_vm.width}px` : _vm.width,
    maxWidth: +_vm.maxWidth ? `${_vm.maxWidth}px` : _vm.maxWidth,
    height: +_vm.height ? `${_vm.height}px` : _vm.height,
  })},[_c('img',{class:['d-block base-img__img', _vm.imgClassName],attrs:{"src":_vm.src,"loading":_vm.lazy ? 'lazy' : undefined,"draggable":_vm.draggable}})])
}
var staticRenderFns = []

export { render, staticRenderFns }