import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class BottomSheet extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly value!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly noGutters!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly dense!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly persistent!: boolean;

  private showedContent = false;
  private showedMenu = false;

  private toggleMenu(showed = !this.showedMenu) {
    this.showedMenu = showed;
  }

  private closeMenu() {
    this.toggleMenu(false);
  }

  private mounted() {
    let transitionTimeoutId = 0;

    this.$watch(
      () => {
        return this.value;
      },
      (value) => {
        this.showedMenu = value;
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      () => {
        return this.showedMenu;
      },
      (showedMenu) => {
        window.clearTimeout(transitionTimeoutId);

        this.showedContent = true;

        if (!showedMenu) {
          transitionTimeoutId = window.setTimeout(() => {
            this.showedContent = false;
            this.$emit("input", false);
          }, 300);
        } else {
          this.$emit("input", true);
        }
      }
    );

    this.$once("hook:beforeDestroy", () => {
      window.clearTimeout(transitionTimeoutId);
    });
  }
}
