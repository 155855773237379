import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { isEmpty, capitalize, sleep } from "@helpers";
import { CardGroup } from "@/types/card";
import { getFallbackCardGroup } from "@/lib/creaditCard";
import { cardGroupModule, profileModule } from "@store/namespaces";
import {
  CardGroupGetters,
  CardGroupActions,
} from "@store/modules/card/modules/group/types";
import { ProfileGetters } from "@store/modules/profile/types";

import BaseDropDown from "@/components/BaseDropDown/BaseDropDown.vue";

@Component({
  inheritAttrs: false,
  components: {
    BaseDropDown,
  },
})
export default class CardGroupList extends Vue {
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @cardGroupModule.Getter("cardGroupsCurrentUser")
  private readonly cardGroupsCurrentUserGetter!: CardGroupGetters["cardGroupsCurrentUser"];
  @cardGroupModule.Getter("loadingCardGroups")
  private readonly loadingCardGroupsGetter!: CardGroupGetters["loadingCardGroups"];
  @cardGroupModule.Action("fetchCardGroups")
  private readonly fetchCardGroupsAction!: CardGroupActions["fetchCardGroups"];

  @Ref("list")
  private readonly listRef?: {
    focus: () => void;
    blur: () => void;
    activateMenu: () => void;
    validate: () => boolean;
  };
  @Prop({ type: String, default: "autocomplete" })
  private readonly type!: string;
  @Prop({ type: Boolean, default: true }) private readonly required!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly xSmall!: boolean;
  @Prop({ type: Boolean, default: false })
  private readonly excludeFallbackGroups!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly autofocus!: boolean;
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean;
  @Prop({ type: [Number, String] }) private readonly value?: number | string;
  @Prop({ type: [Number, String] }) private readonly menuMaxWidth?:
    | number
    | string;

  private currentGroup: CardGroup | string | null = null;

  private get cardGroups() {
    if (this.excludeFallbackGroups) {
      return this.cardGroupsCurrentUserGetter.filter(
        (cardGroup) => !!cardGroup.id
      );
    }

    return this.cardGroupsCurrentUserGetter;
  }

  private get localListeners() {
    const $listeners = { ...this.$listeners };

    delete $listeners.change;

    return $listeners;
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !this.required ||
        !isEmpty(v) ||
        this.$vuetify.lang.t("$vuetify.errors.required"),
      maxCharsGroupName: (v: CardGroup | string | null) => {
        const groupNameLength =
          (typeof v === "string" ? v.length : v?.name?.length) || 0;

        if (this.counter && groupNameLength > this.counter) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.max_сhars",
            this.counter
          );
        }

        return true;
      },
    };
  }

  private get counter() {
    if (this.type === "combobox") {
      return 15;
    }
  }

  private async onChangeCardGroup(val: string | CardGroup) {
    this.$emit("update:validated", this.listRef?.validate() ?? true);

    if (typeof val === "string") {
      const newGroupName = capitalize(
        val
          .replace(/[^a-zа-я0-9 ]+/gi, " ")
          .trim()
          .split(" ")
          .filter((w) => w.trim().length)
          .join(" ") || ""
      );

      this.$emit("change", null);

      const fallbackCardGroup = getFallbackCardGroup({
        ownerEmail: this.profileEmailGetter,
      });

      this.$nextTick(() => {
        if (
          fallbackCardGroup.name.toLowerCase() !== newGroupName.toLowerCase()
        ) {
          this.$emit("change", newGroupName);
        } else {
          this.$emit("change", -1);
        }
      });
    } else {
      this.$emit("change", val);
    }
  }

  private created() {
    this.fetchCardGroupsAction();
  }

  private mounted() {
    this.$watch(
      () => {
        return this.autofocus && !this.loadingCardGroupsGetter;
      },
      async (autofocus) => {
        if (!autofocus) return;

        await this.$nextTick();
        await sleep(100);

        this.listRef?.focus();
        this.listRef?.activateMenu();
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      () => {
        return [
          this.loadingCardGroupsGetter,
          this.cardGroups.length,
          this.value,
        ].join("-");
      },
      () => {
        if (this.loadingCardGroupsGetter) return;

        if (!this.value) {
          this.currentGroup = null;
        } else if (typeof this.value === "string") {
          this.currentGroup =
            this.cardGroups.find(
              (cardGroup) => cardGroup.name === this.value
            ) || this.value;
        } else {
          this.currentGroup =
            this.cardGroups.find((cardGroup) => cardGroup.id === this.value) ||
            null;
        }
      },
      {
        immediate: true,
      }
    );
  }
}
